<template>
    <!--中奖提示-->
    <div class="mask" v-if="prize && list.length > 0">
        <div class="blin"></div>
        <div class="caidai"></div>
        <div class="winning" v-if="!isEmpty" >
            <div class="red-head"></div>
            <div class="red-body"></div>
            <div :class="{ 'card': true, 'center': list.length <= 5 }"  @click="$emit('close')" >
                <div class="center" v-for="(item, index) in list" :key="index">
                    <img :src="item.image" v-if="item.image">
                    <span>
                        {{ item.remark }}
                    </span>
                </div>
                <div class="mt8">
                    <span>总金额:</span>
                    <span>{{ info.total_price }}</span>
                </div>
                <!-- <div class="mt8">
                    <span>回报率:</span>
                    <span>{{ info.rate + '%' }}</span>
                </div> -->
            </div>
            <span @click="$emit('close')" class="btn"></span>
        </div>
        <div v-if='isEmpty' class="losing">
            <span @click="$emit('close')" class="btn"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "tips",
    props: ['prize'],
    data() {
        return {
            isEmpty: false,
            list: this.prize.data,
            info: this.prize.info
        }
    },
    mounted() {
        this.isEmpty = this.list.every(i => i.type == 2);
        this.initJquery()
    },
    methods: {
        initJquery() {
            $(function () {
                var $mask = $(".mask"),//红包遮罩层
                    $winning = $(".winning"),//红包
                    $card = $(".card"),
                    $close = $(".close");
                $winning.addClass("reback");
                setTimeout(function () {
                    $card.addClass("pull");
                }, 500);
            });
        }
    },
}
</script>

<style scoped>
.center {
    justify-content: center;
}

.mt8 {
    margin-top: .2rem;
}
</style>
