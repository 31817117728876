<template>
    <div class="wrap">
      <div class="iconBox">
        <img src="../../assets/prizeClaw/image/return.png" alt="" class="rule"  @click="close">
        <img  src="../../assets/prizeClaw/image/help.png"  alt=""  class="closeGame" @click="showRule = true" />
      </div>
      <div class="box">
        <div>
          <img
            src="../../assets/prizeClaw/image/dollmachine.png"
            alt=""
            class="dollmachine"
          />
          <img src="../../assets/prizeClaw/image/eyel.png" alt="" class="eyeImagel">
          <img src="../../assets/prizeClaw/image/eye.png" alt="" class="eyeImager">
          <img src="../../assets/prizeClaw/image/claw.png" alt="" class="clawImage">
        </div>
  
        <div class="btns">
          <div @click="batchPlay('one')" class="playBtn" :class="{ one: oneImg }">
            <span>1次</span>
          </div>
          <div
            @click="batchPlay('five')"
            class="playBtn"
            :class="{ five: fiveImg }"
          >
            <span>5次</span>
          </div>
          <div @click="batchPlay('ten')" class="playBtn" :class="{ ten: tenImg }">
            <span>10次</span>
          </div>
         
        </div>
      </div>
  
      <div class="bgGIf" v-if="showEggGif">
        <img :src="bgGIf[catchDom]" alt="" class="gifAnimation" />
      </div>
  
      <audio id="audio" ref="audio">
        <source src="../../assets/prizeClaw/video/prize-claw-video.mp3" type="audio/wav" />
      </audio>
      <rule
        :content="dataObj['gold']['remark']"
        v-show="showRule"
        @close="showRule = false"
      />
      <prize v-if="showPrize" :prize="prize" @close="reset" />
    </div>
  </template>
  
  <script>
  import "@/assets/prizeClaw/css/index.css";
  import rule from "@/components/rule";
  import prize from "@/components/prize";
  import { Toast } from "vant";
  
  import tdImg from "../../assets/newegg/image/new-egg-one.png";
  import jdImg from "../../assets/newegg/image/new-egg-two.png";
  import ydImg from "../../assets/newegg/image/new-egg-three.png";
  import bottIcon from "../../assets/newegg/image/bottIcon.png";
  
  import jdBgGif from "../../assets/newegg/image/new-two.gif";
  import ydBgGif from "../../assets/newegg/image/new-three.gif";
  import tdBgGif from "../../assets/newegg/image/new-one.gif";
  
  import activeImg from "../../assets/newegg/image/new-hammer.png";
  
  export default {
    components: {
      prize,
      rule,
    },
    data() {
      return {
        showRule: false,
        showPrize: false,
        gold: 0,
        prize: null,
        tdImg,
        jdImg,
        ydImg,
        bottIcon,
        relevanceObj: {
          copper: tdImg,
          gold: jdImg,
          silver: ydImg,
        },
  
        catchDom: "gold",
        requestCompleted: true,
        oneImg: true,
        fiveImg: false,
        tenImg: false,
        num: "one",
        dataObj: {
          gold: {},
          silver: {},
          prizeClaw:{}
        },
        activeImg,
        playNum: "1",
        showEggGif: false,
        bgGIf: {
          gold: jdBgGif,
          silver: ydBgGif,
          copper: tdBgGif,
        },
      };
    },
    mounted() {
      this.getInfo();
    },
    watch: {
      "globalData.XPLATFORM": {
        handler(newVal) {
          newVal && this.getInfo();
        },
      },
    },
  
    methods: {
      getInfo() {
        if (this.globalData.XPLATFORM) {
          this.$http
            .get("lottery-game/info", {
              params: { code: "toy_crane" },
              headers: {
                "X-PLATFORM": this.globalData.XPLATFORM,
              },
            })
            .then((res) => {
              this.dataObj.prizeClaw = res.data;
              this.gold = res.data["gold"];
              this.dataObj.gold.remark = res.data.remark;
            });
        }
      },
      reset() {
        this.$refs.audio.pause();
        $("#" + this.catchDom).attr("src", this.relevanceObj[this.catchDom]);
        this.showPrize = false;
        this.isShowImg = true;
        this.requestCompleted = true;
        this.getInfo();
      },
      play() {
        //   this.isShowImg = false;
        const limit = this.num == "one" ? "price" : this.num + "_price";
 
        if (parseInt(this.gold) < parseInt(this.dataObj.prizeClaw[limit])) {
          return Toast.fail({ message: "余额不足", position: "bottom" });
        }
        // this.catchDom = dom;
        // $('#' + dom).attr('src', this.gifObj[dom]);
        this.getData(this.num, "toy_crane");
      },
      batchPlay(type) {
        if (type == "one") {
          this.num = 'one';
          this.oneImg = true;
          this.fiveImg = false;
          this.tenImg = false;
          this.playNum='1'
          this.play()
        } else if (type == "five") {
          this.num = "five";
          this.oneImg = false;
          this.fiveImg = true;
          this.tenImg = false;
          this.playNum='5'
          this.play()
        } else if (type == "ten") {
          this.num = "ten";
          this.oneImg = false;
          this.fiveImg = false;
          this.tenImg = true;
          this.playNum='10'
          this.play()
        }
      },
      getData(type, dom) {
        if (!this.globalData.XPLATFORM) {
          return;
        }
        if (!this.requestCompleted) {
          return;
        }
        this.requestCompleted = false;
        const that = this;
        this.$refs.audio.load();
        this.$refs.audio.play();
        this.$refs.audio.playbackRate = 0.5;
        this.$http
          .post(
            "lottery-game/draw",
            {
              type,
              code: dom,
              room_id: that.globalData.roomId,
            },
            {
              headers: { "X-PLATFORM": this.globalData.XPLATFORM },
            }
          )
          .then((res) => {
            this.prize = res;
              setTimeout(() => {
            that.showEggGif = false;
            that.showPrize = true;
            this.$refs.audio.playbackRate = 1;
              }, 800);
          })
          .catch((e) => {
            this.reset();
          });
      },
    },
  };
  </script>
  
  <style scoped></style>
  