<template>
    <div class="mask-rule">
        <div class="box-rule">
            <span class="star"></span>
            <h2>活动规则说明</h2>
            <span class="close-rule" @click="$emit('close')"></span>
            <div class="con">
                <div class="text" v-html="content">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "rule",
        props: {
            content: {
                type: String,
                default: ''
            }
        }
    }

</script>

<style scoped>

</style>
